@font-face {
  font-family: "GT Planar";
  src: url("../fonts/GT-Planar-Regular.woff2") format("woff2"),
    url("../fonts/GT-Planar-Regular.woff") format("woff")
    url("../fonts/GT-Planar-Regular.ttf") format("true-type");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Maxi Round";
  src: url("../fonts/ABCMaxiRound-Regular.otf") format("opentype");
}


.app-logo {
  max-height: 30px;
  pointer-events: none;
  margin: 15px 10px;
  align-self: flex-start;
}

@media (max-width: 520px) {
  .app-logo {
    max-height: 20px;
  }
}

.Toastify__toast {
  font-size: 16px;
  font-family: "GT Planar", Helvetica, Arial, sans-serif !important;
}

.Toastify__close-button {
  align-self: center !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "GT Planar", sans-serif;
  background-image: url("../images/Faucet_BG.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: contrast(0.5);
  padding: 10px;
  min-height: 100vh;
  box-sizing: border-box;
}

.app-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: -1;
}

.title {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.title h1,
.title h2 {
  margin-top: 0;
  margin-bottom: 0;
  color: #F0EBDE;
  text-align: center;
  font-weight: normal;
}

.title h1 {
  font-family: "Maxi Round", sans-serif;
  font-size: 4.5rem;
  text-transform: uppercase;
}

.title h2 {
  font-size: 2.5rem;
}

@media (max-width: 900px) {
  .title h1 {
    font-size: 3rem;
  }

  .title h2 {
    font-size: 2rem;
  }
}

h3 {
  /* font-family: "Maxi Round", sans-serif; */
  font-size: 1.6rem;
  color: white;
  color: #F0EBDE;
  font-weight: normal;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

ul {
  color: white;
  font-size: 1rem;
  margin-top: 0;
  list-style: circle;
}

li {
  padding-bottom: 0.5rem;
}

li a {
  color: white;
}