.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(240, 235, 222, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loading-spinner {
  border: 4px solid #dd7143;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
